import React from 'react';
import EuroIcon from '@mui/icons-material/Euro';
import { Avatar, Card, CardContent, Stack, SvgIcon, Typography, SxProps, Theme } from '@mui/material';

interface OverviewPriceGenericProps {
  label: string;
  totalRevenue: string | number;
  totalMedicalTestRevenue: number;
  totalConsultationRevenue: number;
  sx?: SxProps<Theme>;
}

export const OverviewPriceGeneric: React.FC<OverviewPriceGenericProps> = ({
                                                                            label,
                                                                            totalRevenue,
                                                                            totalMedicalTestRevenue,
                                                                            totalConsultationRevenue,
                                                                            sx
                                                                          }) => {

  const showDetails = totalMedicalTestRevenue  > 0 && totalConsultationRevenue > 0;

  return (
      <Card sx={sx}>
        <CardContent>
          <Stack
              alignItems="flex-start"
              direction="row"
              justifyContent="space-between"
              spacing={1}
          >
            <Stack spacing={1}>
              <Typography color="text.secondary" variant="h5">
                {label}
              </Typography>
              <Typography variant="h2">{totalRevenue} </Typography>
              {showDetails && (<>
                  <Typography color="text.secondary" variant="h5">
                    {`${totalMedicalTestRevenue.toLocaleString()}€ grâce aux dépistages `}
                  </Typography>
                  <Typography color="text.secondary" variant="h5">
                     {`${totalConsultationRevenue.toLocaleString()}€ avec les entretiens`}
                </Typography></>
              )}
            </Stack>
            <Avatar
                sx={{
                  backgroundColor: 'primary.main',
                  height: 46,
                  width: 46
                }}
            >
              <SvgIcon>
                <EuroIcon />
              </SvgIcon>
            </Avatar>
          </Stack>
        </CardContent>
      </Card>
  );
};