import React from 'react';
import {
    Avatar,
    Card,
    CardContent,
    Stack,
    SvgIcon,
    Typography,
    SxProps,
    Theme
} from '@mui/material';
import BiotechIcon from '@mui/icons-material/Biotech';
interface OverviewTotalMedicalTestProps {
    value: string | number;
    label: string;
    sx?: SxProps<Theme>;
}

export const OverviewTotalMedicalTest: React.FC<OverviewTotalMedicalTestProps> = ({
                                                                                      value,
                                                                                      label,
                                                                                      sx,
                                                                                  }) => {
    return (
        <Card sx={sx}>
            <CardContent>
                <Stack
                    alignItems="flex-start"
                    direction="row"
                    justifyContent="space-between"
                    spacing={3}
                >
                    <Stack spacing={1}>
                        <Typography color="text.secondary" variant="h5">
                            {label}
                        </Typography>
                        <Typography variant="h2">{value}</Typography>
                    </Stack>
                    <Avatar
                        sx={{
                            backgroundColor: 'success.main',
                            height: 46,
                            width: 46
                        }}
                    >
                        <SvgIcon>
                                <BiotechIcon/>
                        </SvgIcon>
                    </Avatar>
                </Stack>
            </CardContent>
        </Card>
    );
};