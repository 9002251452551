import React, { useState, useEffect } from 'react';
import {
    Paper,
    Grid,
    ListItem,
    Box,
    Typography,
    List, Stack
} from "@mui/material";

// Function to render ListItem content, common in both Grid and List


function HorizontalSelect(props) {
    const [choices] = useState(props?.choices ?? ['Oui', 'Non']);
    const [values] = useState(props?.values ?? [true, false]);
    const [numberOfColumns] = useState(props?.numberOfColumns ?? null);
    const initialIndex = values.indexOf(props.value);
    const [selectedChoiceIndex, setSelectedChoiceIndex] = useState(initialIndex);
    const [borderRadius] = useState(props?.borderRadius ?? '4px' );
    const [disabled] = useState(props?.disabled ?? false );

    useEffect(() => {
      const stringValues = values.map(value => value.toString());
      if(props.debug){
        console.log("values.map(value => value.toString()) " +values.map(value => value.toString()));
        console.log("props.value "+props.value);
        console.log("stringValues.includes(props.value.toString()) "+(stringValues ? stringValues.includes(props.value+""): stringValues))
      }

      const valueIsDefined = !(props.value === null || props.value === undefined)
      if (valueIsDefined && stringValues.includes(props.value.toString())){
        const index = stringValues.indexOf(props.value.toString());
          setSelectedChoiceIndex(index);
      }
      else if(!valueIsDefined){
          setSelectedChoiceIndex(-1);
      }

    }, [props,values]);
  
    const handleChoiceClick = (index) => {
       if(disabled) return;
      if(index === selectedChoiceIndex){
        //deselect
         setSelectedChoiceIndex(-1);
        props.changeValue(null);
        return;
      }
      setSelectedChoiceIndex(index);
      const newValue = values[index];
      props.changeValue(newValue);
    };

    const renderChoiceItem = (choice, choiceIndex) => (
        <ListItem
            key={choiceIndex}
            disablePadding
            onClick={() => handleChoiceClick(choiceIndex)}
            sx={{ cursor: (disabled ? "auto": "pointer") }}

        >
            <Paper
                variant="outlined"
                sx={{
                    height: "100%",
                    width: "100%",
                    borderRadius: borderRadius,
                    backgroundColor:
                        choiceIndex === selectedChoiceIndex ? disabled ? "lightgrey" : "lightblue" : "white",
                }}
            >
                <Box
                    sx={{
                        height: "100%",
                        display: "flex",
                        minHeight: "40px",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "5px"
                    }}
                >
                    <Typography variant="text" alignItems="center">{choice}</Typography>
                </Box>
            </Paper>
        </ListItem>
    );

    return (

        // Conditional rendering for Grid or List
        typeof numberOfColumns === 'number' ? (
            <Grid container columns={Math.min(numberOfColumns, values.length)}>
                {choices.map((choice, choiceIndex) => (
                    <Grid item xs={1} key={choiceIndex}>
                        {renderChoiceItem(choice, choiceIndex)}
                    </Grid>
                ))}
            </Grid>
        ) : (
                <List component={Stack} direction="row" disablePadding>
                    {choices.map((choice, choiceIndex) => renderChoiceItem(choice, choiceIndex))}
                </List>
            )

    );
  }
  

export default HorizontalSelect;