import React, {useEffect, useState} from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    Button,
    CircularProgress,
    FormHelperText,
    Grid,
    Paper,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import HorizontalSelect from '../../../components/field/horizontalSelect.component';
import {CystitisStatus, CystitisTest, CystitisTreatment, MedicalTestStatus, Patient} from '../../../types/types.ts';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import MedicalTestResultSelect from "../../../components/field/MedicalTestResultSelect.tsx";
import {connect, useDispatch} from "react-redux";
import {Dispatch} from "redux";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';
import MedicalTestResultChip from "../../../components/field/MedicalTestResulChip.tsx";
import Chip from "@mui/material/Chip";
import EmailIcon from "@mui/icons-material/Email";
import SendResultCystitis from "./SendResultCystitis.tsx";
import UtilService from "../../../services/util.service.js"
import MedicalTestService, {MEDICAL_TEST_URL_IDENTIFIER} from "../../../services/MedicalTestService.ts";
import PharmacyService from "../../../services/pharmacy.service";
import {getActionDisplayMessage} from "../../../actions/ReduxAction.ts";
import {useNavigate} from "react-router-dom";
import SaveIcon from "@mui/icons-material/Save";

interface CystitisTestProps {
    medicalTest: CystitisTest;
    patient : Patient;
}


const CystisisTestDetail: React.FC<CystitisTestProps> = ({ medicalTest, patient }) => {
    const [currentTest, setCurrentTest] = useState(medicalTest);
    const [cystitisQuestionStatus, setCystitisQuestionStatus]
        = useState({status: medicalTest.cystitisStatus, text:"" });
    const [isLoading, setIsLoading] = useState(false);
    const [showCystitisQuestions, setShowCystitisQuestions] = React.useState(true);
    const [forceShowCystitisQuestions, setForceShowCystitisQuestions] = React.useState(false);

    const resultHasBeenSent : boolean = currentTest.emailDate && currentTest.emailResult;
    const [isDialogOpen, setDialogOpen] = useState(false);

    const canSendReportToPatient : boolean = !currentTest.emailDate  && ((currentTest.status === MedicalTestStatus.POSITIVE &&  currentTest.positiveTestWithAllergies !== null &&  currentTest.antibioticsPrescribed !== null ) || (currentTest.status === MedicalTestStatus.POSITIVE &&  currentTest.positiveTestWithAllergies === true) ||  (currentTest.status === MedicalTestStatus.NEGATIVE && currentTest.negatifTestWithAnomaly!== null )|| currentTest.status === MedicalTestStatus.INCONCLUSIVE);
    const emailReportHasBeeSent : boolean = currentTest.emailDate  && currentTest.emailResult;
    const questionsAreDisabled : boolean = emailReportHasBeeSent;
    const displayAdditionalInfoErrorWhenNegative : boolean = (currentTest.status === MedicalTestStatus.NEGATIVE && currentTest.negatifTestWithAnomaly === null);
    const displayPrescriptionErrorWhenPositive : boolean =  currentTest.status === MedicalTestStatus.POSITIVE && (currentTest.positiveTestWithAllergies === null || (currentTest.positiveTestWithAllergies === false && currentTest.antibioticsPrescribed === null));
    const displayBillingCode10 :boolean = (currentTest.status === MedicalTestStatus.POSITIVE && currentTest.positiveTestWithAllergies) || currentTest.status === MedicalTestStatus.NEGATIVE || currentTest.status === MedicalTestStatus.INCONCLUSIVE || currentTest.withCystitisPrescription;
    const displayBillingCode15 :boolean = (currentTest.status === MedicalTestStatus.POSITIVE) && currentTest.antibioticsPrescribed && !currentTest.withCystitisPrescription && !displayBillingCode10;
    const displayTestConclusion :boolean = emailReportHasBeeSent || (cystitisQuestionStatus.status ===  CystitisStatus.CAN_TEST_PATIENT  && currentTest.status !== MedicalTestStatus.IN_PROGRESS && currentTest.status !== MedicalTestStatus.CANCELED);
    const displayCommentForDoctor :boolean = (currentTest.status === MedicalTestStatus.NEGATIVE || currentTest.status === MedicalTestStatus.POSITIVE);
    const displayAdditionalQuestion :boolean = (currentTest.status === MedicalTestStatus.NEGATIVE || currentTest.status === MedicalTestStatus.POSITIVE) && (cystitisQuestionStatus.status ===  CystitisStatus.CAN_TEST_PATIENT);
    const attachmentWithMail :boolean = (currentTest.status === MedicalTestStatus.NEGATIVE && currentTest.negatifTestWithAnomaly === true) || currentTest.status === MedicalTestStatus.POSITIVE;
    const dispatch: Dispatch = useDispatch();
    const navigate = useNavigate();

    const [pharmacy, setPharmacy] = useState(patient.pharmacyRef);

    useEffect(() => {
            PharmacyService.getPharmacy(patient.pharmacyRef.id).then((response) => {
                setPharmacy(response.data);
            }).catch((error) => {
                dispatch(getActionDisplayMessage("Une erreur s'est produite lors de la recupération de vos données de Pharmacie, veuillez recharger la page ou contacter le support", 'error'));
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ,[patient]);


    // Used to set the status to "Cancelled" when the patient is not eligible,
    // and revert it back to "In Progress" when the questioning starts again.
    useEffect( () => {
        if(currentTest.status === MedicalTestStatus.IN_PROGRESS && cystitisQuestionStatus.status === CystitisStatus.NOT_ALLOWED_TO_TEST ){
            setCurrentTest(prevState => ({
                ...prevState,
                status: MedicalTestStatus.CANCELED
            }));
        }
        if(currentTest.status === MedicalTestStatus.CANCELED && cystitisQuestionStatus.status === CystitisStatus.NOT_ENOUGH_INFO ){
            setCurrentTest(prevState => ({
                ...prevState,
                status: MedicalTestStatus.IN_PROGRESS
            }));
        }
    }, [currentTest.status,cystitisQuestionStatus.status])


    const getQuestionsData = () => [
        {
            field: 'isFemale',
            text: 'Patiente de sexe féminin ?',
            value: currentTest.isFemale,
            transitions: {
                true: 'withCystitisPrescription',
                false: 'NOT_ALLOWED_TO_TEST',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: true, // Initial state is visible
            greenIconWhenValueIsTrue: true
        },
        {
            field: 'withCystitisPrescription',
            text: 'Avec une ordonnance de dispensation conditionnelle avec la mention « si BU (Bandelette Urinaire Positif) + » ?',
            value: currentTest.withCystitisPrescription,
            transitions: {
                true: 'CAN_TEST_PATIENT',
                false: 'isMoreThan16andLessThan65',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
            infoIcon: true
        },
        {
            field: 'isMoreThan16andLessThan65',
            text: 'Avez-vous entre 16 et 65 ans ?',
            value: currentTest.isMoreThan16andLessThan65,
            transitions: {
                true: 'hasFever',
                false: 'NOT_ALLOWED_TO_TEST_AGE',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
            greenIconWhenValueIsTrue: true
        },
        {
            field: 'hasFever',
            text: 'Avez-vous eu de la fièvre supérieur à 38°C ou frissons dans les dernières 72 heures ?',
            value: currentTest.hasFever,
            transitions: {
                true: 'SHOULD_CONSULT_EMERGENCY_FEVER',
                false: 'withPyelonephritisRisk',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
            greenIconWhenValueIsTrue: false
        },
        {
            field: 'withPyelonephritisRisk',
            text: 'Avez-vous eu des douleurs au niveau des fosses lombaires ou au niveau des flancs/ des vomissements/ diarrhées/ douleurs abdominales ?',
            value: currentTest.withPyelonephritisRisk,
            transitions: {
                true: 'SHOULD_CONSULT_EMERGENCY_PYELO',
                false: 'pregnancyRisk',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
            greenIconWhenValueIsTrue: false
        },
        {
            field: 'pregnancyRisk',
            text: "Êtes-vous enceinte ou des chances l'être?",
            value: currentTest.pregnancyRisk,
            transitions: {
                true: 'SHOULD_CONSULT_EMERGENCY_PREGNANT',
                false: 'gynecologicalRisk',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
            greenIconWhenValueIsTrue: false
        },
        {
            field: 'gynecologicalRisk',
            text: 'Avez-vous des signes gynécologiques tels que des pertes blanches (leucorrhées) ou des démangeaisons vaginales (prurit vulvaire ou vaginal) ?',
            value: currentTest.gynecologicalRisk,
            transitions: {
                true: 'SHOULD_CONSULT_EMERGENCY_GYNECO',
                false: 'hadCystitisInTheLastYear',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
            greenIconWhenValueIsTrue: false
        },
        {
            field: 'hadCystitisInTheLastYear',
            text: 'Avez-vous eu au cours des 12 derniers mois 3 ou plus épisodes de cystite ?',
            value: currentTest.hadCystitisInTheLastYear,
            transitions: {
                true: 'SHOULD_CONSULT_DOCTOR_YEAR',
                false: 'hadCystitisInTheLastWeeks',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
            greenIconWhenValueIsTrue: false
        },
        {
            field: 'hadCystitisInTheLastWeeks',
            text: 'Avez-vous eu au cours des 15 derniers jours un autre épisode de cystite ou le même épisode toujours non résolu ?',
            value: currentTest.hadCystitisInTheLastWeeks,
            transitions: {
                true: 'SHOULD_CONSULT_DOCTOR_WEEKS',
                false: 'withUrinaryAnomalies',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
            greenIconWhenValueIsTrue: false
        },
        {
            field: 'withUrinaryAnomalies',
            text: 'Avez-vous une anomalie connue de l’arbre urinaire (résidu vésical, reflux, lithiase, tumeur, geste chirurgical récent endoscopique ou sondage…) ?',
            value: currentTest.withUrinaryAnomalies,
            transitions: {
                true: 'SHOULD_CONSULT_DOCTOR_URINARY',
                false: 'withImmunoRisk',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
            greenIconWhenValueIsTrue: false
        },
        {
            field: 'withImmunoRisk',
            text: 'Avez-vous un risque d’immunodépression dû à une pathologie (VIH) ou à un médicament (corticothérapie au long cours, chimiothérapie, immunosuppresseurs) ?',
            value: currentTest.withImmunoRisk,
            transitions: {
                true: 'SHOULD_CONSULT_DOCTOR_IMMUNO',
                false: 'withImplantedCatheter',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
            greenIconWhenValueIsTrue: false
        },
        {
            field: 'withImplantedCatheter',
            text: 'Portez-vous un cathéter veineux implanté ?',
            value: currentTest.withImplantedCatheter,
            transitions: {
                true: 'SHOULD_CONSULT_DOCTOR_CATHETER',
                false: 'withRenalFailure',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
            greenIconWhenValueIsTrue: false
        },
        {
            field: 'withRenalFailure',
            text: "Souffrez-vous d'insuffisance rénale chronique sévère (DFG < 30mL/min/1,72m2 selon CKD-EPI) ?",
            value: currentTest.withRenalFailure,
            transitions: {
                true: 'SHOULD_CONSULT_DOCTOR_RENAL',
                false: 'hasAntibioticTreatment',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
            greenIconWhenValueIsTrue: false
        },
        {
            field: 'hasAntibioticTreatment',
            text: 'Avez-vous un traitement d’antibiotique en cours pour une autre pathologie ?',
            value: currentTest.hasAntibioticTreatment,
            transitions: {
                true: 'SHOULD_CONSULT_DOCTOR_ANTIBIOTIC',
                false: 'hadFluoroquinolones',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
            greenIconWhenValueIsTrue: false
        },
        {
            field: 'hadFluoroquinolones',
            text: 'Avez-vous pris des fluoroquinolones dans les trois derniers mois ?',
            value: currentTest.hadFluoroquinolones,
            transitions: {
                true: 'SHOULD_CONSULT_DOCTOR_FLUORO',
                false: 'CAN_TEST_PATIENT',
                null: 'NOT_ENOUGH_INFO'
            },
            visible: false,
            greenIconWhenValueIsTrue: false
        }
    ];
    const [questions, setQuestions] = useState(getQuestionsData());


    const states = {
        NOT_ALLOWED_TO_TEST: { status: CystitisStatus.NOT_ALLOWED_TO_TEST, text: "Impossible de réaliser un test de depistage de cystite sur un patient de sexe masculin" },
        NOT_ALLOWED_TO_TEST_YOUNG: { status: CystitisStatus.NOT_ALLOWED_TO_TEST, text: "Impossible de réaliser un test de depistage de cystite sur une patiente de moins de 16 ans même avec une ordonnance" },
        NOT_ALLOWED_TO_TEST_AGE: { status: CystitisStatus.NOT_ALLOWED_TO_TEST, text: "Impossible de réaliser un test de dépistage de cystite sur une patiente de moins de 16 ans ou plus de 65 ans" },
        SHOULD_CONSULT_EMERGENCY_FEVER: { status: CystitisStatus.SHOULD_CONSULT_EMERGENCY, text: "Impossible de réaliser un test de dépistage de cystite sur une patiente atteinte de fièvre" },
        SHOULD_CONSULT_EMERGENCY_PYELO: { status: CystitisStatus.SHOULD_CONSULT_EMERGENCY, text: "Impossible de réaliser un test de dépistage de cystite sur une patiente atteinte de douleurs dans le bas du dos car c'est un risque de Pyélonéphrite" },
        SHOULD_CONSULT_EMERGENCY_PREGNANT: { status: CystitisStatus.SHOULD_CONSULT_EMERGENCY, text: "Impossible de réaliser un test de dépistage de cystite sur une patiente qui peut être enceinte" },
        SHOULD_CONSULT_EMERGENCY_GYNECO: { status: CystitisStatus.SHOULD_CONSULT_EMERGENCY, text: "Impossible de réaliser un test de dépistage de cystite sur une patiente avec des signes gynécologiques" },
        SHOULD_CONSULT_DOCTOR_YEAR: { status: CystitisStatus.SHOULD_CONSULT_DOCTOR, text: "Impossible de réaliser un test de dépistage de cystite sur une patiente ayant eu 3 ou plus épisodes de cystite au cours des 12 derniers mois" },
        SHOULD_CONSULT_DOCTOR_WEEKS: { status: CystitisStatus.SHOULD_CONSULT_DOCTOR, text: "Impossible de réaliser un test de dépistage de cystite sur une patiente ayant eu un autre épisode de cystite au cours des 15 derniers jours" },
        SHOULD_CONSULT_DOCTOR_URINARY: { status: CystitisStatus.SHOULD_CONSULT_DOCTOR, text: "Impossible de réaliser un test de dépistage de cystite sur une patiente avec une anomalie connue de l’arbre urinaire" },
        SHOULD_CONSULT_DOCTOR_IMMUNO: { status: CystitisStatus.SHOULD_CONSULT_DOCTOR, text: "Impossible de réaliser un test de dépistage de cystite sur une patiente avec un risque d’immunodépression" },
        SHOULD_CONSULT_DOCTOR_CATHETER: { status: CystitisStatus.SHOULD_CONSULT_DOCTOR, text: "Impossible de réaliser un test de dépistage de cystite sur une patiente portant un cathéter veineux implanté" },
        SHOULD_CONSULT_DOCTOR_RENAL: { status: CystitisStatus.SHOULD_CONSULT_DOCTOR, text: "Impossible de réaliser un test de dépistage de cystite sur une patiente souffrant d'insuffisance rénale chronique sévère" },
        SHOULD_CONSULT_DOCTOR_ANTIBIOTIC: { status: CystitisStatus.SHOULD_CONSULT_DOCTOR, text: "Impossible de réaliser un test de dépistage de cystite sur une patiente avec un traitement d’antibiotique en cours" },
        SHOULD_CONSULT_DOCTOR_FLUORO: { status: CystitisStatus.SHOULD_CONSULT_DOCTOR, text: "Impossible de réaliser un test de dépistage de cystite sur une patiente ayant pris des fluoroquinolones dans les trois derniers mois" },
        CAN_TEST_PATIENT: { status: CystitisStatus.CAN_TEST_PATIENT, text: "Vous pouvez réaliser le test" },
        NOT_ENOUGH_INFO: { status: CystitisStatus.NOT_ENOUGH_INFO, text: "Pas assez de réponse, pour connaitre l'élligibilité de la patiente" }
    };


    const determineQuestionsToShow = () => {
        let newQuestions = getQuestionsData();
        let currentState = newQuestions.find(q => q.field === 'isFemale');
        while (currentState) {
            const response = currentTest[currentState.field];
            const nextStateKey = currentState.transitions[response];


            if (nextStateKey === null) {
                // Not enough information to proceed
                setQuestions(newQuestions);
                setCystitisQuestionStatus(states.NOT_ENOUGH_INFO);
                return;
            }

            const nextState = newQuestions.find(q => q.field === nextStateKey) || states[nextStateKey];

            if (!nextState) {
                // End of the state machine
                setShowCystitisQuestions(false);
                setQuestions(newQuestions);
                return;
            }

            if (nextState.visible !== undefined) {
                nextState.visible = true;
            } else {
                // Terminal state
                setQuestions(newQuestions);
                setCystitisQuestionStatus(nextState);
                if(nextStateKey === CystitisStatus.CAN_TEST_PATIENT){
                    setShowCystitisQuestions(false);
                }
                return;
            }

            currentState = nextState;
        }
    };


    useEffect(() => {
        determineQuestionsToShow();
        // eslint-disable-next-line
    }, [currentTest]);

    const handleFieldChange = (field, value) => {
        setCurrentTest(prevState => ({
            ...prevState,
            [field]: value
        }));
    };
    const handleSendReport = () => {
        setIsLoading(true);
        MedicalTestService.saveCystitisTest(currentTest).then((savedCurrentTest :CystitisTest) => {
            setIsLoading(false);
            setCurrentTest(savedCurrentTest);
            setDialogOpen(true);
        })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
                dispatch(
                    getActionDisplayMessage(
                        "Une erreur s'est produite lors de l'enregistrement des modifications",
                        "error"));
            });
    }

    const handleSaveAsDraft = () => {
      setIsLoading(true);
        MedicalTestService.saveCystitisTest(currentTest).then(() => {
            setIsLoading(false);
            dispatch(getActionDisplayMessage("Test Enregistré avec succès","success"));
            navigate(`/patient/${patient.id}/${MEDICAL_TEST_URL_IDENTIFIER}/`);
        })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
                dispatch(
                    getActionDisplayMessage(
                        "Une erreur s'est produite lors de l'enregistrement des modifications",
                        "error"));
            });
    }

    const renderIcon = (question) => {

        if (question.infoIcon) return <InfoIcon style={{ color: 'lightsteelblue' }} />;
        if (question.value === true)
            return  question.greenIconWhenValueIsTrue ?  <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />;
        if (question.value === false)
            return  question.greenIconWhenValueIsTrue ?  <CancelIcon style={{ color: 'red' }} /> : <CheckCircleIcon style={{ color: 'green' }} /> ;
        return '';
    };

    if (isLoading) return <CircularProgress />;

    return (
        <Grid container justifyContent="center" p={2}>
            <Grid item xs={12} sm={12} md={12} lg={11} xl={10}>
                <Paper variant="outlined">
                    <Stack direction="row" mb={3} justifyContent={"center"}>
                        <Typography variant="h3">
                            Dépistage de Cystite du {new Date(currentTest.testDate).toLocaleDateString()} pour {patient.firstName} {patient.lastName}
                        </Typography>
                    </Stack>
                </Paper>
                <Paper variant="outlined">
                    <Box p={2} display="block">
                        <Typography variant="h4" align="center">
                            Information sur le patient
                        </Typography>
                        <Grid container spacing={2} mt={2}>
                            <Grid item md={4}>
                                <Typography variant="h5" color="grey">{patient.firstName} {patient.lastName}</Typography>
                            </Grid>
                            <Grid item md={4}>
                                <Typography variant="h5" color="grey">Né le {new Date(patient.birthday).toLocaleDateString()}</Typography>
                            </Grid>
                            <Grid item md={4}>
                                <Typography variant="h5" color="grey">Numéro de SS: {patient.socialSecurityNumber}</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
                <Paper variant="outlined">
                    <Accordion  expanded={showCystitisQuestions||forceShowCystitisQuestions} onChange={() => setForceShowCystitisQuestions(!forceShowCystitisQuestions)}>


                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                        <Typography variant="h4" align="center" width={'100%'}>
                            Questionnaire de Cystite
                        </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Grid container spacing={2} mt={2} pl={1}>
                            {questions.map((question, index) => (
                                question.visible && (
                                    <Grid container key={index} mb={1}>
                                        <Grid item xs={12} sm={6}>
                                            <Stack direction="row" spacing={1}>
                                                {renderIcon(question)}
                                                <Typography variant="h5" fontWeight={index === questions.length-1 || (questions[index + 1] && !questions[index + 1].visible) ? "bold" : "light"}>
                                                    {question.text}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <HorizontalSelect
                                                choices={['Oui', 'Non',]}
                                                values ={[true, false]}
                                                disabled ={questionsAreDisabled}
                                                changeValue={(value) => handleFieldChange(question.field, value)}
                                                value={question.value}
                                            />
                                        </Grid>
                                    </Grid>
                                )
                            ))}
                        </Grid>
                        </AccordionDetails>

                    </Accordion>
                </Paper>
                <Paper variant="outlined">
                    <Box p={2} display="block">
                        <Typography variant="h4" align="center">
                            Orientation du test
                        </Typography>
                        <Grid container spacing={2} mt={2}>
                            {(cystitisQuestionStatus.status) && (
                                (cystitisQuestionStatus.status === CystitisStatus.CAN_TEST_PATIENT ?
                                    <Alert severity="info" style={{width:'100%',marginLeft:'16px'}} >
                                        <Typography px={2} variant="h5" align="center">
                                            Le test de dépistage cystite ( par bandelette urinaire ) peut être réalisé avec l'accord de la patiente
                                        </Typography>
                                    </Alert> : ''))}
                            {(cystitisQuestionStatus.status) && (
                                (cystitisQuestionStatus.status === CystitisStatus.NOT_ALLOWED_TO_TEST ?
                                    <Alert severity="error" style={{width:'100%',marginLeft:'16px'}} >
                                        <Typography px={2} variant="h5" align="center">
                                            Le test de dépistage cystite ( par bandelette urinaire ) ne peut PAS être réalisé { cystitisQuestionStatus.text ? ' : '+cystitisQuestionStatus.text : ''}
                                        </Typography>
                                    </Alert> : ''))}
                            {(cystitisQuestionStatus.status) && (
                                (cystitisQuestionStatus.status === CystitisStatus.NOT_ENOUGH_INFO ?
                                    <Alert severity="warning" style={{width:'100%',marginLeft:'16px'}} >
                                        <Typography px={2} variant="h5" align="center">
                                            Le test de dépistage cystite ne peut PAS encore être réalisé { cystitisQuestionStatus.text ? ' : '+cystitisQuestionStatus.text : ''}
                                        </Typography>
                                    </Alert> : ''))}

                            {(cystitisQuestionStatus.status) && (
                                (cystitisQuestionStatus.status === CystitisStatus.SHOULD_CONSULT_DOCTOR ?
                                    <Alert severity="error" style={{width:'100%',marginLeft:'16px'}} >
                                        <Typography px={2} variant="h5" align="center">
                                            Le test de dépistage cystite ne peut PAS être réalisé, vous devriez orienter la patiente vers son medecin traitant { cystitisQuestionStatus.text ? ' : '+cystitisQuestionStatus.text : ''}
                                        </Typography>
                                    </Alert> : ''))}

                            {(cystitisQuestionStatus.status) && (
                                (cystitisQuestionStatus.status === CystitisStatus.SHOULD_CONSULT_EMERGENCY ?
                                    <Alert severity="error" style={{width:'100%',marginLeft:'16px'}} >
                                        <Typography px={2} variant="h5" align="center">
                                            Le test de dépistage cystite ne peut PAS être réalisé, vous devriez orienter la patiente vers un service d'urgence { cystitisQuestionStatus.text ? ' : '+cystitisQuestionStatus.text : ''}
                                        </Typography>
                                    </Alert> : ''))}
                        </Grid>
                    </Box>
                </Paper>
                {cystitisQuestionStatus.status ===  CystitisStatus.CAN_TEST_PATIENT  && (
                    <Paper variant="outlined" >
                        <Box p={2} display="block">
                            <Typography variant="h4" align="center" sx={{paddingBottom:4}}>
                                Résultat du Test
                            </Typography>
                            <Grid container  p={1}>
                                <Grid item md={12} xs={12}>
                                    <MedicalTestResultSelect showInconclusive={true} disabled={resultHasBeenSent} testResult={currentTest.status} changeValue={(newTestResult)=> handleFieldChange('status', newTestResult)}/>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                )}
                {currentTest.status === MedicalTestStatus.POSITIVE && cystitisQuestionStatus.status === CystitisStatus.CAN_TEST_PATIENT  && (
                    <Paper variant="outlined" >
                        <Box p={2} display="block">
                            <Typography variant="h4" align="center" sx={{paddingBottom:4}}>
                                Traitement antibiotique
                            </Typography>

                            <Grid container mb={1}>
                                <Grid item xs={12} sm={6}>
                                    <Stack direction="row" spacing={1}>
                                        <Typography variant="h5" >
                                            Allergie ou contre indication à la fosfomycine ou au pivmecillinam
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <HorizontalSelect
                                        choices={['Oui', 'Non',]}
                                        values ={[true, false]}
                                        changeValue={(value) => handleFieldChange('positiveTestWithAllergies', value)}
                                        value={currentTest.positiveTestWithAllergies}
                                    />
                                </Grid>
                        {(currentTest.positiveTestWithAllergies === false) &&  (<>
                            <Grid item xs={12} sm={12} >
                                <Stack direction="row" spacing={1} py={3}>
                                    <Typography variant="h5" >
                                        Antibiotique à prescrire
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <HorizontalSelect
                                    choices={['FOSFOMYCINE TROMETAMOL' +
                                    '3 g per os en une prise unique',
                                        'PIVMECILLINAM 400 mg per os\n' +
                                    '2 fois par jour pendant 3 jours', 'Autre']}
                                    values={[CystitisTreatment.FOSFOMYCINE, CystitisTreatment.PIVMECILLINAM,CystitisTreatment.OTHER]}
                                    changeValue={(value) => handleFieldChange('antibioticsPrescribed', value) }
                                    value={currentTest.antibioticsPrescribed}
                                />
                            </Grid>
                            {(currentTest.antibioticsPrescribed === CystitisTreatment.OTHER) && ( <>
                                <Typography p={1} pt={3} variant="h5" align="center">
                                    Détail sur le traitement
                                </Typography>
                                <Grid container spacing={1}>
                                    <Grid item md={12} sm={12}>
                                        <TextField
                                            id="otherTreatment"
                                            multiline
                                            fullWidth
                                            value={currentTest.prescriptionComment || ""}
                                            onChange={(event) => handleFieldChange('prescriptionComment', event.target.value)}
                                            rows={4}
                                            placeholder="Vos remarques sur le traitement"
                                            variant="outlined"
                                            inputProps={{ maxLength: 250 }}
                                        />
                                        <FormHelperText id="comment">250 caractères max</FormHelperText>
                                    </Grid>
                                </Grid>
                            </> )}
                        </>)}
                            </Grid>
                        </Box>
                    </Paper>
                )}
                {displayAdditionalQuestion && (
                    <Paper variant="outlined" >
                        <Box p={2} display="block">
                            <Typography variant="h4" align="center" sx={{paddingBottom:4}}>
                                Question(s) complémentaire(s)
                            </Typography>
                            {(currentTest.status === MedicalTestStatus.NEGATIVE) && (
                                <Grid container mb={1}>
                                    <Grid item xs={12} sm={6}>
                                        <Stack direction="row" spacing={1}>
                                            <Typography variant="h5" >
                                                 Présence d’une hématurie ou d’une autre anomalie ?
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <HorizontalSelect
                                            choices={['Oui', 'Non',]}
                                            values ={[true, false]}
                                            changeValue={(value) => handleFieldChange('negatifTestWithAnomaly', value)}
                                            value={currentTest.negatifTestWithAnomaly}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                            {displayCommentForDoctor && ( <>
                                <Typography p={1} pt={3} variant="h5" align="center">
                                    Vos remarques à transmettre au medecin
                                </Typography>
                                <Grid container spacing={1}>
                                    <Grid item md={12} sm={12}>
                                        <TextField
                                            id="conclusion"
                                            multiline
                                            fullWidth
                                            value={currentTest.commentForDoctor || ""}
                                            onChange={(event) => handleFieldChange('commentForDoctor', event.target.value)}
                                            minRows={2}
                                            maxRows={10}
                                            placeholder="Vos remarques"
                                            variant="outlined"
                                            inputProps={{ maxLength: 250 }}
                                        />
                                        <FormHelperText id="comment">250 caractères max</FormHelperText>
                                    </Grid>
                                </Grid>
                            </> )}
                        </Box>
                    </Paper>
                )}

                {displayTestConclusion && (
                    <Paper variant="outlined" >
                        <Box p={2} display="block">
                            <Typography variant="h4" align="center" sx={{paddingBottom:4}}>
                                Conclusion du Test
                            </Typography>
                            {(currentTest.status === MedicalTestStatus.INCONCLUSIVE) &&(
                            <Typography variant="h5" align="center" sx={{paddingBottom:4}}>
                                   Sans résultat interpretable vous devriez orienter la patiente vers son medecin traitant
                            </Typography>
                                )}

                            {(currentTest.status === MedicalTestStatus.POSITIVE || currentTest.status === MedicalTestStatus.NEGATIVE  ) &&(<>
                            <Typography variant="h5" align="center">
                                Le résultat du test est  <MedicalTestResultChip result={currentTest.status} />  aux leucocytes et nitrites.
                            </Typography>
                            <Typography variant="h5" align="center">
                                { currentTest.status === MedicalTestStatus.POSITIVE && currentTest.positiveTestWithAllergies ? " Vous n'avez pas prescrit d'antibiotiques suites à une contre indications et vous avez redirigé la patiente vers son medecin traitant":''}
                            </Typography>
                            <Typography variant="h5" align="center">
                                { currentTest.status === MedicalTestStatus.NEGATIVE && currentTest.negatifTestWithAnomaly ? " Vous avez remarqué la presence d'anomalie dans les urines et vous avez redirigé la patiente vers son medecin traitant":''}
                            </Typography>
                                </>
                             )}
                            {displayBillingCode10 &&
                                <Typography component={"div"} variant="body1" align="center" sx={{paddingBottom:4}}>
                                Le code de facturation est
                                    <Box component="span" mx={1}>
                                       <Chip  style={{backgroundColor: '#221821F6', color: '#ffffff', margin:'3px', fontWeight: 'bold' }} label={'PEE'}/> pour une rémunération de <Chip  style={{backgroundColor: '#529b5d', color: '#ffffff', margin: '3px', fontWeight: 'bold' }} label={'10 € TTC'}/>
                                    </Box>
                                    </Typography>
                            }
                            {displayBillingCode15 &&(
                                <Typography variant="body1" align="center" sx={{paddingBottom:4}}>
                                    Le code de facturation est
                                    <Box component="span" mx={1}>
                                       <Chip  style={{backgroundColor: '#221821F6', color: '#ffffff', margin:'3px', fontWeight: 'bold' }} label={'PEE'}/>
                                    </Box>
                                        pour une rémunération de
                                    <Box component="span" mx={1}>
                                       <Chip  style={{backgroundColor: '#529b5d', color: '#ffffff', margin: '3px', fontWeight: 'bold' }} label={'15 € TTC'}/>
                                    </Box>
                                </Typography>
                            )}
                            {displayPrescriptionErrorWhenPositive && (
                            <Alert severity="warning" style={{width:'100%',marginLeft:'16px'}} >
                                <Typography px={2} variant="h5" align="center">
                                    Veuillez remplir les informations sur le traitement antibiotique prescrit
                                </Typography>
                            </Alert>
                            )}
                            {displayAdditionalInfoErrorWhenNegative && (
                                <Alert severity="warning" style={{width:'100%',marginLeft:'16px'}} >
                                    <Typography px={2} variant="h5" align="center">
                                        Veuillez remplir les informations sur les Questions complémentaires
                                    </Typography>
                                </Alert>
                            )}
                            {canSendReportToPatient  &&   (
                                <Alert severity="success" style={{width:'100%'}} >
                                    <Typography px={2} variant="h5" >
                                        Envoyer le compte rendu à la patiente pour finaliser le test
                                    </Typography>
                                </Alert>
                            )}
                            {emailReportHasBeeSent &&  (<>
                                <Typography pb={3} variant="h5" align="center">
                                    Test terminé, email et compte rendu envoyé à la patiente avec un resultat <Box component="span" mx={1}> <MedicalTestResultChip result={currentTest.emailResult} /> </Box>
                                    {( UtilService.handleDateFormat(currentTest.emailDate)!=='Non renseigné' ) ?
                                        ` le  ${UtilService.handleDateFormat(currentTest.emailDate)}` : ''}
                                    {(currentTest.emailDate && UtilService.get24HourTime(currentTest.emailDate) ) ?
                                        ` à  ${UtilService.get24HourTime(currentTest.emailDate)}` : ''}

                                </Typography>
                                <Grid item md={12}>
                                    <TextField
                                        label="Adresse Email"
                                        required
                                        value={currentTest.emailAddress || ""}
                                        id="emailAddress"
                                        name="emailAddress"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        disabled
                                    />
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <TextField
                                        id="emailContent"
                                        multiline
                                        fullWidth
                                        value={currentTest.emailContent || ""}
                                        disabled
                                        variant="outlined"
                                    />
                                </Grid>
                            </>)}

                        </Box>
                    </Paper>
                )}

                <Box p={'2px'} />
                <Box p={'2px'} />
                <Button
                    variant={canSendReportToPatient ?"outlined" :"contained" }
                    color="primary"
                    startIcon={<SaveIcon />}
                    onClick={handleSaveAsDraft}
                    sx={{ mr: 1 }}
                >
                    Enregistrer pour plus tard
                </Button>
                {canSendReportToPatient  &&

                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<EmailIcon />}
                    onClick={() =>handleSendReport() }
                    sx={{ mr: 1 }}
                >
                    Envoyer le compte rendu à la patiente
                </Button>
                    }
            </Grid>
            <SendResultCystitis
                isOpen={isDialogOpen}
                onClose={() => setDialogOpen(false)}
                currentTest={currentTest}
                handleCystitisTestFieldChange ={handleFieldChange}
                defaultEmailAddress={currentTest.emailAddress}
                onSendingResult={() =>  navigate(`/patient/${patient.id}/${MEDICAL_TEST_URL_IDENTIFIER}/`)}
                attachmentWithMail={attachmentWithMail}
                emailContent ={currentTest.emailContent}
                pharmacyEmail={pharmacy.email}
            />
        </Grid>
    );
};

function mapStateToProps(state) {
    const { isLoggedIn, user } = state.authReducer;
    return {
        isLoggedIn,
        user
    };
}
export default connect(mapStateToProps)(CystisisTestDetail);