// Spring Data Page structure for TypeScript
export interface Page<T> {
    content: T[];
    pageable: Pageable;
    totalElements: number;
    totalPages: number;
    last: boolean;
    first: boolean;
    size: number;
    number: number;
    numberOfElements: number;
    empty: boolean;
}
// Pageable to understand the paging structure
export interface Pageable {
    sort: Sort;
    offset: number;
    pageSize: number;
    pageNumber: number;
    paged: boolean;
    unpaged: boolean;
}

// Sort structure
export interface Sort {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
}

export interface Patient {
    id: string;
    socialSecurityNumber: string;
    firstName: string;
    lastName: string;
    email: string;
    address?: string;
    mobilePhoneNumber?: string;
    homePhoneNumber?: string;
    pharmacyRef: Pharmacy;
    birthday?: string;
    afiliateRegime?: string;
}

export interface Pharmacy {
    id: string;
    name: string;
    address: string;
}

export interface ConsultationGroup {
    id: string;
    type: string;
    patientRef: Patient;
    pharmacyRef: Pharmacy;
    consultations: Consultation[];
    newConsultationTypes: string[];
    lastModifiedDate: string;
    creationDate: string;
    billingDate?: string;
    TACDate?: string;
    shouldTAC: boolean;
    billingCode?: string;
    isBillable: boolean;
    price: number;
    billed: boolean;
}

export interface Consultation {
    id: string;
    consultationGroupId: string;
    patientRef: Patient;
    pharmacyRef: Pharmacy;
    status: string;
    lastModifiedDate: string;
    creationDate: string;
    documentationFiles: DocumentationFile[];
    type: string;
}
export interface File {
    id: string;
    fileName: string;
    fileType: string;
    fileUrl: string;
}

export interface DocumentationFile extends File{

}

// Enum for consultation types
export enum ConsultationGroupType {
    PREGNANCY = "PREGNANCY",
    CANCER_TREATMENT = "ORAL_CANCER_TREATMENT",
}

export enum ConsultationType {
    CHEMO_DAILY_LIFE_AND_SIDE_EFFECTS = "CHEMO_DAILY_LIFE_AND_SIDE_EFFECTS",
    CHEMO_OBSERVANCE = "CHEMO_OBSERVANCE",
    CHEMO_FIRST = "CHEMO_FIRST",
    PREGNANCY = "PREGNANCY",
}

// Enum for status types
export enum ConsultationStatus {
    DRAFT = "DRAFT",
    VALIDATED = "VALIDATED",
    BILLED = "BILLED",
    ARCHIVED = "ARCHIVED",
}

export enum MedicalTestType {
    COVID19 = "COVID19",
    CYSTITIS = "CYSTITIS",
    SORE_THROAT = "SORE_THROAT"
}

export interface MedicalTest {
    id: string;
    patientRef: Patient;
    pharmacyRef: Pharmacy;
    status: MedicalTestStatus;
    lastModifiedDate: string;
    creationDate: string;
    resultReport: File[];
    type: MedicalTestType;
    emailResult : MedicalTestStatus;
    emailAddress : string
    emailDate : string
    billingDate?: string;
    billingCode?: string;
}


export interface COVID19Test extends MedicalTest {
    testingKit : CovidTestingKit
    batchNumber: string,
    expiryDate : string
    testDate : string
}

export interface CystitisTest extends MedicalTest {
    isFemale : boolean,
    withCystitisPrescription: boolean,
    isMoreThan16andLessThan65 : boolean,
    hasFever: boolean,
    withPyelonephritisRisk : boolean,
    pregnancyRisk : boolean,
    gynecologicalRisk: boolean,
    hadCystitisInTheLastYear : boolean,
    hadCystitisInTheLastWeeks : boolean,
    withUrinaryAnomalies : boolean,
    withImmunoRisk : boolean,
    withImplantedCatheter : boolean,
    withRenalFailure : boolean,
    hasAntibioticTreatment : boolean,
    hadFluoroquinolones : boolean,
    cystitisStatus :CystitisStatus,
    testDate : string
    negatifTestWithAnomaly: boolean,
    commentForDoctor : string
    positiveTestWithAllergies : boolean
    antibioticsPrescribed : CystitisTreatment,
    prescriptionComment :string
    emailContent :string
    hasPharmacyEmailInCC :boolean;
}

export enum CystitisTreatment {
    FOSFOMYCINE= "FOSFOMYCINE",
    PIVMECILLINAM = "PIVMECILLINAM",
    OTHER = "OTHER"
}

export enum CystitisDocument {
    REPORT_FOR_DOCTOR= "REPORT_FOR_DOCTOR",
    LETTER_FOR_DOCTOR= "LETTER_FOR_DOCTOR"

}

export interface CovidTestingKit {
    testName: string;
    manufacturer: string;
}
export enum CystitisStatus {
    SHOULD_CONSULT_DOCTOR= "CONSULT_DOCTOR",
    SHOULD_CONSULT_EMERGENCY = "CONSULT_EMERGENCY",
    CAN_TEST_PATIENT = "CAN_TEST_PATIENT",
    NOT_ALLOWED_TO_TEST= "NOT_ALLOWED_TO_TEST",
    NOT_ENOUGH_INFO="NOT_ENOUGH_INFO"
}
export interface SoreThroatTest extends MedicalTest {
    isLessThan3: boolean;
    isMoreThan10: boolean;
    isMoreThan15: boolean;
    isMoreThan50: boolean;
    withPrescription: boolean;
    hasFeverMoreThan39_5: boolean; // declared by the patient
    hasRealFeverMoreThan39_5: boolean;//measure by the pharmacist
    childThatVomitsOrDiarrhea: boolean;

    hasAlteredVitalSigns: boolean; // Indicates presence of altered vital signs

    presenceOfCough: boolean; // Indicates absence of cough
    hasTenderAnteriorCervicalLymphNodes: boolean; // Indicates presence of tender anterior cervical lymph nodes
    temperatureAbove38: boolean; // Indicates if the temperature is above 38°C
    hasTonsillarSwellingOrExudate: boolean; // Indicates presence of tonsillar swelling or exudate

    insufficientMedicalInformation: boolean; // Indicates insufficient medical information
    childWithRashUnder15YearsOld: boolean; // Indicates child under 15 years old with rash
    symptomsDurationMoreThan8Days: boolean; // Indicates symptoms duration is more than 8 days
    recentTropicalTravelWithoutUpToDateVaccine: boolean; // Indicates recent tropical travel without up-to-date vaccine
    pregnancyRisk: boolean; // Indicates pregnancy risk
    withImmunoRisk: boolean; // Indicates immunodepression risk
    withRenalFailure: boolean; // Indicates renal failure
    hasAntibioticTreatment: boolean; // Indicates current antibiotic treatment
    similarEpisodeInLast15Days: boolean; // Indicates similar episode in the last 15 days
    thirdConsultationForOdynophagiaInSixMonthsWithoutDoctor: boolean; // Indicates third consultation for odynophagia in 6 months without a doctor
    contraindicationToAntibiotics: boolean; // Indicates contraindication to antibiotics
    pharmacistHasDoubt: boolean; // Indicates pharmacist has doubt
    hasVenousCatheter: boolean; // Indicates presence of venous catheter
    allergicToAnyAntibiotics: boolean
    allergicToAvailableAntibiotics: boolean
    soreThroatTestStatus: SoreThroatTestStatus;
    testDate: string; // should be a string or can be a Date type, depending on how date is managed
    commentForDoctor: string;
    prescriptionComment: string;
    emailContent: string;
    hasPharmacyEmailInCC: boolean;
    antibioticsPrescribed: SoreThroatTreatment;
}

export enum SoreThroatTestStatus {
    SHOULD_CONSULT_DOCTOR = "SHOULD_CONSULT_DOCTOR",
    SHOULD_CONSULT_EMERGENCY = "SHOULD_CONSULT_EMERGENCY",
    CAN_TEST_PATIENT = "CAN_TEST_PATIENT",
    NOT_ALLOWED_TO_TEST = "NOT_ALLOWED_TO_TEST",
    NOT_ENOUGH_INFO = "NOT_ENOUGH_INFO"
}

export enum SoreThroatTreatment {
    AMOXICILLIN_2G_PER_DAY_6_DAYS = "AMOXICILLIN_2G_PER_DAY_6_DAYS",
    AMOXICILLIN_50MG_KG_6_DAYS = "AMOXICILLIN_50MG_KG_6_DAYS",
    CEFUROXIME_AXETIL_500MG_4_DAYS = "CEFUROXIME_AXETIL_500MG_4_DAYS",
    CEFPODOXIME_PROXETIL_200MG_5_DAYS = "CEFPODOXIME_PROXETIL_200MG_5_DAYS",
    CEFPODOXIME_PROXETIL_8MG_KG_5_DAYS = "CEFPODOXIME_PROXETIL_8MG_KG_5_DAYS",
    AZITHROMYCINE_500MG_3_DAYS = "AZITHROMYCINE_500MG_3_DAYS",
    AZITHROMYCINE_20MG_KG_3_DAYS = "AZITHROMYCINE_20MG_KG_3_DAYS",
    CLARITHROMYCINE_500MG_5_DAYS = "CLARITHROMYCINE_500MG_5_DAYS",
    CLARITHROMYCINE_15MG_KG_5_DAYS = "CLARITHROMYCINE_15MG_KG_5_DAYS",
    JOSAMYCINE_2G_PER_DAY_5_DAYS = "JOSAMYCINE_2G_PER_DAY_5_DAYS",
    JOSAMYCINE_50MG_KG_5_DAYS = "JOSAMYCINE_50MG_KG_5_DAYS"
}

export enum SoreThroatDocument {
    REPORT_FOR_DOCTOR = "REPORT_FOR_DOCTOR",
    LETTER_FOR_DOCTOR = "LETTER_FOR_DOCTOR"
}
export enum MedicalTestStatus {
    IN_PROGRESS= "IN_PROGRESS",
    POSITIVE = "POSITIVE",
    NEGATIVE = "NEGATIVE",
    CANCELED = "CANCELED",
    INCONCLUSIVE = "INCONCLUSIVE"
}
