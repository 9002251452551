import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, Typography,Paper,Container,Grid,IconButton, Stack,CircularProgress,Alert,AlertTitle} from "@mui/material";
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import { Navigate } from "react-router-dom";
import TrialDialog from '../account/trialDialog.component';
import { OverviewPriceGeneric } from '../../components/overview/OverviewPriceGeneric.tsx';
import { OverviewTotalPatient } from '../../components/overview/overviewTotalPatient.component';
import { OverviewTotalConsultation } from '../../components/overview/overviewTotalConsultation.component';
import { OverviewTotalMedicalTest } from '../../components/overview/OverviewTotalMedicalTest.tsx';
import { useNavigate } from "react-router-dom";
import PharmacyService from '../../services/pharmacy.service';
import { actionDisplayMessage } from '../../actions/auth.action';
 
function DashBoard(props) {
  const [pharmacy, setPharmacy] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalPatient, setTotalPatient] = useState(0);
  const [totalBilledConsultation, setTotalBilledConsultation] = useState(0);
  const [totalInProgressConsultation, setTotalInProgressConsultation] = useState(0);
  const [totalMedicalTest, setTotalMedicalTest] = useState(0);
  const [totalMedicalTestRevenue, setTotalMedicalTestRevenue] = useState(0);
  const [totalConsultationRevenue, setTotalConsultationRevenue] = useState(0);

  
  
  
  const navigate = useNavigate();
  const noDataAvailable = totalRevenue <= 0 && totalPatient <= 0 && totalBilledConsultation <= 0 && totalInProgressConsultation <= 0;

  useEffect(() => { 
   setTotalRevenue(pharmacy?.overviewData?.totalRevenue ?? 0 );
   setTotalPatient(pharmacy?.overviewData?.totalPatient ?? 0 );
   setTotalBilledConsultation(pharmacy?.overviewData?.totalBilledConsultation ?? 0 );
   setTotalInProgressConsultation(pharmacy?.overviewData?.totalInProgressConsultation ?? 0 );
   setTotalMedicalTest(pharmacy?.overviewData?.totalMedicalTest ?? 0 );
   setTotalMedicalTestRevenue(pharmacy?.overviewData?.totalMedicalTestRevenue ?? 0 );
   setTotalConsultationRevenue(pharmacy?.overviewData?.totalConsultationRevenue ?? 0 );

  }
  ,[pharmacy]);

  useEffect(() => { 
    PharmacyService.getCurrentPharmacie().then((response) => {
        setPharmacy(response.data.content[0]);
        setIsLoading(false); 
    }).catch((error) => {
        props.dispatch(
            actionDisplayMessage(
            "Une erreur s'est produite lors de la recupération de vos données de Pharmacie, veuillez recharger la page ou contacter le support",
            "error"));
    })

  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ,[]);

  if (!props.user.accessToken) {
      return <Navigate replace to="/" />;
  }
  if (isLoading) {
    return <CircularProgress />;
  }
  return (
    <Box mx="auto" p={4} width="100%" >
    
      <Paper >            
      <Stack
          alignItems={"center"}
          spacing={3} >
          <Typography variant="h1"py={2}>Bienvenue sur Entretien</Typography>
          <Typography variant="h2"py={2}>Tableau de bord</Typography>
          { noDataAvailable && 
            
            <Box id="boxInfoOverview" sx ={{px:3 }}>

            <Alert severity="info" >  
                <AlertTitle> <Typography  sx ={{py:1 }} variant="h3">Une nouvelle aventure commence pour votre officine</Typography></AlertTitle>
                <Typography  sx ={{py:1 }}  variant="h4"> 
                  Veuillez creer votre premier Patient et decouvrer les possbilités offertent par notre application:
                </Typography>
                <Typography  sx ={{py:1 }}  variant="h6"> 
              Vous retrouvez ici les indicateurs pour suivre vos progrès en matière d'entretiens
                </Typography>
            </Alert>
            </Box>      
          }
      </Stack>
      <Box component="main" sx={{flexGrow: 1,py: 8}}>
        <Container maxWidth="xl">
        <Grid container spacing={1}>
          {totalRevenue > 0 && 
              <Grid item xs={12} sm={6} lg={3} >
              <Box sx={{px:1}}> <Paper variant="outlined"> 
                <OverviewPriceGeneric
                  sx={{ maxHeight: '150px', minHeight: '120px', minWidth: '250px'}}
                  label="Revenu Total"
                  totalMedicalTestRevenue={totalMedicalTestRevenue}
                  totalConsultationRevenue={totalConsultationRevenue}
                  totalRevenue={totalRevenue+ ' €'}
                /></Paper> </Box> 
              </Grid>
          }
          {totalPatient > 0 && 
            <Grid item xs={12} sm={6} lg={3} >
              <Box sx={{px:1}}> <Paper variant="outlined"> 
              <OverviewTotalPatient
                sx={{ maxHeight: '100px' ,minHeight: '120px', minWidth: '250px'}}
                difference={null}
                positive={false}
                label="Patients Accompagnés"
                value={totalPatient}
                /></Paper> </Box> 
            </Grid>
          }
          {totalBilledConsultation > 0 && 
            <Grid item xs={12} sm={6} lg={3} >
              <Box sx={{px:1}}> <Paper variant="outlined"> 
              <OverviewTotalConsultation
                sx={{ maxHeight: '100px' ,minHeight: '120px', minWidth: '250px'}}
                label="Entretiens Facturés"
                value={totalBilledConsultation}
                /></Paper> </Box> 
            </Grid>
          }
          {totalInProgressConsultation > 0 && 
            <Grid item xs={12} sm={6} lg={3}>
              <Box sx={{px:1}}> <Paper variant="outlined"> 
              <OverviewTotalConsultation
                sx={{ maxHeight: '100px' ,minHeight: '120px', minWidth: '250px'}}
                label="Entretiens En cours"
                value={totalInProgressConsultation}
                isWarningInfo={true}
                /></Paper> </Box> 
              </Grid>
          }

          {totalMedicalTest > 0 &&
            <Grid item xs={12} sm={6} lg={3}>
              <Box sx={{px:1}}> <Paper variant="outlined">
              <OverviewTotalMedicalTest
                sx={{ maxHeight: '100px' ,minHeight: '120px', minWidth: '250px'}}
                label="Dépistages réalisés"
                value={totalMedicalTest}
                /></Paper> </Box>
              </Grid>
          }
          </Grid>
          </Container>
          </Box>
            <TrialDialog isDialogOpen={props.user.subscriptionStatus ==='TRIAL'}/>
            <Stack
              direction="row"
              justifyContent="center"
              spacing={3}>
                <Paper elevation={3} sx={{my:2}}>
                <IconButton
                  onClick={() => {navigate(`/patient/new`);}}
                  color="primary"
                  aria-label="Add new patient">
                  <Typography variant="h4" sx={{py:1,mb:1}}>Ajouter un nouveau patient</Typography>
                  <LibraryAddIcon sx={{mb:2,ml:1}} />
              </IconButton>
              </Paper>
            </Stack>
          </Paper>
          </Box>
  )
  
};

function mapStateToProps(state) {
    const user  = state.authReducer.user;
    return {
      user
    };
  }
  
  
  export default connect(mapStateToProps)(DashBoard);